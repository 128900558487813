import React, { useEffect, useState } from "react";
import { getEventsinner } from '../Service/Api';

const AcademicCalendar = () => {
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const circularData1 = await getEventsinner(); 
            const circularData = circularData1.filter((list)=>list.category === "AcademicCalender")
            setData(circularData);
            setDataLoaded(true); // Set dataLoaded to true after data is fetched
            console.log("getacademic calender", circularData);
        };
        fetchData();
    }, []);

    const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const updateMonth = (newMonthIndex, year) => {
        const monthName = monthArr[newMonthIndex];
        setCurrentMonth(`${monthName}-${year}`);
    };

    useEffect(() => {
        if (dataLoaded) {
            updateMonth(currentMonthIndex, currentYear);
        }
    }, [currentMonthIndex, currentYear, dataLoaded]);

    useEffect(() => {
        if (dataLoaded) {
            const getMonthName = document.querySelectorAll(".academic-cal > div");
            Array.from(getMonthName).forEach((item) => {
                if (item.getAttribute('data-month') === monthArr[currentMonthIndex]) {
                    item.classList.add('active');
                } else {
                    item.classList.remove('active');
                }
            });
        }
    }, [currentMonth, dataLoaded]);

    const handleNext = () => {
        setCurrentMonthIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % 12;
            if (newIndex === 0) {
                setCurrentYear((prevYear) => prevYear + 1);
            }
            return newIndex;
        });
    };

    const handlePrevious = () => {
        setCurrentMonthIndex((prevIndex) => {
            const newIndex = (prevIndex - 1 + 12) % 12;
            if (newIndex === 11) {
                setCurrentYear((prevYear) => prevYear - 1);
            }
            return newIndex;
        });
    };

    return (
        <div className='news-block'>
            <div className="title">
                <h2 className='title_main m-auto'><span>A</span>cademic Calendar</h2>
                <div className='button'>
                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/prev-shape.png" className="prev" onClick={handlePrevious} alt="Previous" />
                    {currentMonth}
                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/next-shape.png" className="next" onClick={handleNext} alt="Next" />
                </div>
            </div>
            <div className="academic-cal">
                {data.length > 0 ? (
                    data.map((item, index) => {
                        const date = new Date(item.date);
                        const monthName = date.toLocaleString('default', { month: 'short' });
                        return (
                            <div data-month={monthName} key={index}>
                                <p>{item.title}</p>
                                <p>{`${date.getDate()} ${monthName}`}</p>
                            </div>
                        );
                    })
                ) : (
                    <> </>
                )}
            </div>
        </div>
    );
};

export default AcademicCalendar;
